
import { defineComponent, inject } from "vue";
import { AxiosStatic } from "axios";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "../store";

export const subscriptionComponent = defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;

    const hide = async () => {
      store.commit("setUserShowSubscriptionEndPopup", false);
      router.push("/");
    };

    const noShow = async () => {
      await http.post(
        `patient-portal/api/patient-profile/disable-popup/show-subscription-end`
      );
      store.commit("setUserShowSubscriptionEndPopup", false);
      router.push("/");
    };

    return {
      hide,
      noShow,
      t,
    };
  },
});

export default subscriptionComponent;
