import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44923034"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subscription" }
const _hoisted_2 = { class: "subscription__box" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "subscription__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", {
        innerHTML: _ctx.t('SUBSCRIPTION.END.TITLE')
      }, null, 8 /* PROPS */, _hoisted_3),
      _createVNode(_component_a_divider),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("SUBSCRIPTION.END.DESCRIPTION")), 1 /* TEXT */),
      _createElementVNode("div", null, [
        _createVNode(_component_a_button, {
          class: "subscription__button",
          type: "primary",
          "html-type": "submit",
          onClick: _ctx.hide
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("SUBSCRIPTION.END.HIDE")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_a_button, {
          class: "subscription__button",
          type: "primary",
          "html-type": "submit",
          onClick: _ctx.noShow
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("SUBSCRIPTION.END.NO_SHOW_AGAIN")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ])
  ]))
}